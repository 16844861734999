import request from '@/utils/request'

const faceApi = {
    queryRecord: '/api/v1/faceVisitRecord/queryRecord'
}

export function faceQueryRecord(params) {
    const url = request.requestFormat(faceApi.queryRecord, params)
    return request({
        url,
        method: 'get'
    })
} 
