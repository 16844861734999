<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="客户姓名">
                <a-input v-model="queryParam.clientName" allow-clear placeholder="请输入客户姓名" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="客户手机号">
                <a-input v-model="queryParam.clientPhone" allow-clear placeholder="请输入客户手机号" />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="经纪人姓名">
                  <a-input v-model="queryParam.clientName" allow-clear placeholder="请输入经纪人姓名" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="经纪人手机号">
                  <a-input v-model="queryParam.clientPhone" allow-clear placeholder="请输入经纪人手机号" />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="orderData"
        :alert="true"
        :totalCount="totalCount"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="judgeStatus" slot-scope="text, record">
          <a-popover v-if="text !== 0 && text !== null" title="到访记录时间" trigger="click" placement="bottom">
            <div slot="content" class="judge_popover">
              <a-timeline v-if="faceRecord.length !== 0">
                <a-timeline-item v-for="item in faceRecord" :key="item.id">到访记录时间：{{ item.gainTime }}</a-timeline-item>
              </a-timeline>
              <a-empty v-else></a-empty>
            </div>
            <a-tag class="myTag" :color="text | judgeStatusTypeFilter" @click="fetchFaceRecord(record)">{{ text |judgeStatusFilter }}</a-tag>
          </a-popover>
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">查看</a>
          </template>
        </span>
      </s-table>

      <a-drawer 
        title="" 
        class="myDescription" 
        placement="right" 
        width="600px" 
        :visible="visible" 
        @close="onClose">
        <a-card :bordered="false">
          <a-descriptions :title="drawer.id ? '订单号：' + drawer.id : '订单号：-'"></a-descriptions>
          <a-descriptions
            :title="
              '关联报备单号：' +
                (drawer._embedded ? drawer._embedded.report.id : drawer.report ? drawer.report.id : '-')
            "
          ></a-descriptions>
          <a-descriptions :title="'楼盘名称：' + (drawer.houseName ? drawer.houseName : '-')"></a-descriptions>
          <template v-if="drawer.status !== 0">
            <a-descriptions title="签约信息" class="sign">
              <a-descriptions-item label="客户姓名" span="3">{{
                drawer.clientName ? drawer.clientName : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="客户手机号" span="3">{{
                drawer.clientPhone ? drawer.clientPhone : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="经纪人" span="3">{{
                drawer.brokerName ? drawer.brokerName : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="经纪人手机号" span="3">{{ drawer._embedded ? drawer._embedded.report.broker.phone : drawer.report ? drawer.report.broker.phone : '-' }}</a-descriptions-item>
              <a-descriptions-item label="置业顾问" span="3">{{
                drawer.consultantName ? drawer.consultantName : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="签约房号" span="3">{{
                drawer.contractRoom ? drawer.contractRoom : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="房屋总价" span="3">{{
                drawer.contractPrice ? drawer.contractPrice : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="签约时间" span="3">{{
                drawer.contractTime ? drawer.contractTime : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="合同照片" span="3" v-if="contractPhotos">
                <a-card
                  hoverable
                  style="width: 120px"
                  v-for="item in contractPhotos"
                  :key="item.id"
                  class="inlineBlock"
                >
                  <media :file="item" />
                </a-card>
              </a-descriptions-item>
            </a-descriptions>
            <a-divider style="margin-bottom: 32px" />
          </template>
          <a-descriptions title="认购信息" class="sign">
            <template v-if="drawer.status === 0">
              <a-descriptions-item label="客户姓名" span="3">{{
                drawer.clientName ? drawer.clientName : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="客户手机号" span="3">{{
                drawer.clientPhone ? drawer.clientPhone : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="经纪人" span="3">{{
                drawer.brokerName ? drawer.brokerName : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="经纪人手机号" span="3">{{ drawer._embedded ? drawer._embedded.report.broker.phone : drawer.report ? drawer.report.broker.phone : '-' }}</a-descriptions-item>
              <a-descriptions-item label="置业顾问" span="3">{{
                drawer.consultantName ? drawer.consultantName : '-'
              }}</a-descriptions-item>
            </template>
            <a-descriptions-item label="认购房号" span="3">{{ drawer.room ? drawer.room : '-' }}</a-descriptions-item>
            <a-descriptions-item label="房屋总价" span="3">{{ drawer.price ? drawer.price : '-' }}</a-descriptions-item>
            <a-descriptions-item label="认购时间" span="3">{{
              drawer.createTime ? drawer.createTime : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="预计签约时间" span="3">{{
              drawer.signDate ? drawer.signDate : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="备注" span="3">{{ drawer.remark ? drawer.remark : '-' }}</a-descriptions-item>
            <a-descriptions-item label="认购单照片" span="3" v-if="photos">
              <a-card hoverable style="width: 120px" v-for="item in photos" :key="item.id" class="inlineBlock">
                <media :file="item" />
              </a-card>
            </a-descriptions-item>
          </a-descriptions>
          <a-divider style="margin-bottom: 32px" />
          <a-descriptions title="到访信息" class="sign">
            <a-descriptions-item label="驻场人员" span="3">{{ drawer._embedded ? drawer._embedded.report.acceptUser.name : drawer.report ? drawer.report.acceptUser.name : '-' }}</a-descriptions-item>
            <a-descriptions-item label="置业顾问" span="3">{{ drawer._embedded ? drawer._embedded.report.consultant.name : drawer.report ? drawer.report.consultant.name : '-' }}</a-descriptions-item>
            <a-descriptions-item label="到访时间" span="3">{{
              drawer._embedded ? drawer._embedded.report.acceptTime : drawer.report ? drawer.report.acceptTime : '-'
            }}</a-descriptions-item>
            <a-descriptions-item
              label="到访照片"
              span="3"
              v-if="(drawer._embedded && drawer._embedded.report.evidence) || (drawer.report && drawer.report.evidence)"
            >
              <a-card
                hoverable
                style="width: 120px"
                v-for="item in drawer._embedded ? drawer._embedded.report.evidence : drawer.report.evidence"
                :key="item.id"
                class="inlineBlock"
              >
                <media :file="item" />
              </a-card>
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions title="日志" class="log"> </a-descriptions>
        </a-card>
      </a-drawer>
    </a-card>
  </page-header-wrapper>
</template>
<script>
import { STable, Ellipsis, Media } from '@/components'

import { queryOrders, contractPhotos, getPhotos } from '@/api/orders'
import { columns, statusMap, judgeStatus } from './js/index'
import { faceQueryRecord } from '@/api/face'

export default {
  components: {
    STable,
    Ellipsis,
    Media
  },
  data() {
    return {
      columns,
      statusMap,
      judgeStatus,
      visible: false,
      advanced: false,
      queryParam: {},
      totalCount: 0,
      drawer: {},
      contractPhotos: [],
      photos: [],
      faceRecord: [],
      orderData: parameter => {
        const param = {
          userId: this.userId,
          status: 1
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, this.queryParam)
        return queryOrders(params).then(res => {
          if (res.success) {
            const result = this.resFormat(res)
            this.totalCount = result.totalCount
            return result
          }
        })
      }
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    },
    judgeStatusFilter(type) {
      return judgeStatus[type].text
    },
    judgeStatusTypeFilter(type) {
      return judgeStatus[type].color
    }
  },
  computed: {
    userId() {
      return this.$store.state.user.info.id
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    handleEdit(record) {
      this.drawer = record
      this.visible = true
      contractPhotos(record.id).then(res => {
        this.contractPhotos = res._embedded.medias
      })
      getPhotos(record.id).then(res => {
        this.photos = res._embedded.medias
      })
    },
    onClose() {
      this.visible = false
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    fetchFaceRecord(record) {
      this.faceRecord = []
      const reportId = record.report.id
      const param = {
        reportId,
        sort: 'gainTime'
      }
      const params = Object.assign(this.maxPagination, param)
      faceQueryRecord(params).then(res => {
        if (res.success) {
          this.faceRecord = res.data.content
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.log {
  /deep/ .ant-descriptions-item-content {
    span:first-child {
      font-weight: 700;
    }
    span:last-child {
      font-weight: 700;
      margin-left: 50px;
    }
  }
  /deep/ .ant-descriptions-item-label {
    font-weight: 700;
  }
}
.sign {
  /deep/ .ant-card {
    width: 120px;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 20px;
    margin-top: 20px;
  }
}
.judge_popover {
  padding: 5px 20px;
  max-height: 300px;
  overflow-y: scroll;
}
</style>
