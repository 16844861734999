import request from '@/utils/request'

const ordersApi = {
  // get orders info
  base: '/api/v1',
  orders: '/api/v1/rest/orders',
  query: '/api/v1/order/query',
  findByKeyword: '/api/v1/rest/orders/search/findByKeyword',
  findByStatus: '/api/v1/rest/orders/search/findByStatus'
}

export function orders (params) {
  let url = ordersApi.orders
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function addOrders (params) {
  return request({
    url: ordersApi.orders,
    method: 'post',
    data: params
  })
}

export function editOrders (params) {
  return request({
    url: ordersApi.orders + `/${params.id}`,
    method: 'patch',
    data: params
  })
}

export function delOrders (params) {
  return request({
    url: ordersApi.orders + `/${params.id}`,
    method: 'delete'
  })
}

export function deleteOrders (params) {
  return request({
    url: ordersApi.base + `/order/delete`,
    method: 'get',
    params
  })
}

export function findOrdersByStatus (params) {
  let url = ordersApi.findByStatus
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function queryOrders (params) {
  let url = ordersApi.query
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findOrders (params) {
  let url = ordersApi.findByKeyword
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findByIdOrders (params) {
  return request({
    url: ordersApi.orders + `/${params.id}`,
    method: 'get'
  })
}

export function inOrders (params) {
  return request({
    url: ordersApi.orders + `/${params.id}/${params.name}`,
    method: 'get'
  })
}

 export function finish(data) {
  let url = ordersApi.base + '/order/commission/finish'
  if (data) {
    url += '?' + Object.keys(data).map(m => `${m}=${data[m]}`).join('&')
  }
   return request({
     url,
     method: 'put'
   })
 }
 
export function fail(data) {
  let url = ordersApi.base + '/order/commission/fail'
  if (data) {
    url += '?' + Object.keys(data).map(m => `${m}=${data[m]}`).join('&')
  }
  return request({
    url,
    method: 'put'
  })
}
 
export function back(data) {
  let url = ordersApi.base + '/order/money/back'
  if (data) {
    url += '?' + Object.keys(data).map(m => `${m}=${data[m]}`).join('&')
  }
  return request({
    url,
    method: 'put',
    data
  })
}

export function money(data) {
  let url = ordersApi.base + '/order/money/finish'
  if (data) {
    url += '?' + Object.keys(data).map(m => `${m}=${data[m]}`).join('&')
  }
  return request({
    url,
    method: 'put'
  })
}

export function orderReport(id) {
  const url = ordersApi.orders + `/${id}/report`
  return request({
    url,
    method: 'get'
  })
}

export function contractResident(id) {
  const url = ordersApi.orders + `/${id}/contractResident`
  return request({
    url,
    method: 'get'
  })
}

export function contractPhotos(id) {
  const url = ordersApi.orders + `/${id}/contractPhotos`
  return request({
    url,
    method: 'get'
  })
}

export function getPhotos(id) {
  const url = ordersApi.orders + `/${id}/photos`
  return request({
    url,
    method: 'get'
  })
}
