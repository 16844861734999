export const columns = [
  {
    title: '楼盘名称',
    dataIndex: 'houseName'
  },
  {
    title: '订单号',
    dataIndex: 'id'
  },
  {
    title: '客户',
    dataIndex: 'clientName'
  },
  {
    title: '经纪人',
    dataIndex: 'brokerName'
  },
  {
    title: '签约时间',
    dataIndex: 'contractTime',
    sorter: true
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '判客状态',
    dataIndex: 'judgeStatus',
    scopedSlots: { customRender: 'judgeStatus' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

export const statusMap = {
  0: {
    status: 'default',
    text: '已认购'
  },
  1: {
    status: 'processing',
    text: '已签约'
  },
  2: {
    status: 'success',
    text: '回款中'
  },
  3: {
    status: 'success',
    text: '已回款'
  },
  4: {
    status: 'success',
    text: '结佣中'
  },
  5: {
    status: 'success',
    text: '已结佣'
  },
  '-1': {
    status: 'success',
    text: '退房'
  },
  '-2': {
    status: 'success',
    text: '结佣异议'
  }
}

export const judgeStatus = {
  2: {
    status: 'warning',
    color: 'orange',
    text: '截客'
  },
  1: {
    status: 'Processing',
    color: 'blue',
    text: '正常'
  },
  '-1': {
    status: 'error',
    color: 'red',
    text: '飞单'
  },
  null: {
    status: 'Default',
    color: 'gray',
    text: '未抓取相关数据'
  },
  0: {
    status: 'Default',
    color: 'gray',
    text: '未抓取相关数据'
  }
}
